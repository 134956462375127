<template>
  <div class="position-relative">
    <MomoNav>
      <div class="nav-item">
        <a href="#">
          <img class="" src="./images/momo_button_profile.png" />
        </a>
      </div>
      <div class="nav-item">
        <router-link :to="{ name: 'LiffHuggiesMomoMemberIndex' }">
          <img class="" src="./images/momo_button_home.png" />
        </router-link>
      </div>
    </MomoNav>
    <div class="huggies-verify">
      <div class="form position-relative">
        <div class="field">
          <div class="field-label mb-3">會員姓名</div>
          <input type="text" placeholder="請輸入真實姓名" v-model="form.name" />
        </div>
        <div class="field">
          <div class="mb-3">
            <div class="field-label">聯絡電話</div>
            <small>若手機號碼重新輸入，會員表單裡電話資料會同步修改 </small>
          </div>
          <div class="input-group">
            <input style="flex: 1 1 auto; width: 1%;" type="tel" placeholder="請輸入手機號碼" v-model="form.mobile"/>
            <div class="input-group-append pl-2">
              <button class="btn btn-outline-primary" :disabled="!needVerify" type="button" @click="showVerifyDialog">手機驗證</button>
            </div>
          </div>
        </div>
        <div class="field">
          <div class="mb-3">
            <div class="field-label mb-1">收取禮品地址</div>
            <small class="d-block"
              >請填妥：郵遞區號/縣市/鄉鎮市區/路 巷 弄 號 /樓 室
            </small>
            <small class="d-block"
              >【若資料不完整或地址無法辨識，將無法完成寄件】</small
            >
          </div>

          <div class="d-flex city-selector-set-has-value mb-1">
            <!-- 縣市選單 -->
            <select
              class="county w-50"
              :data-value="form.county"
              v-model="form.county"
              @change="form.district = ''"
            ></select>

            <!-- 區域選單 -->
            <select
              class="district ml-2 w-50"
              :data-value="form.district"
              v-model="form.district"
            ></select>

            <input
              id="zipcode"
              type="hidden"
              class="zipcode"
            />
          </div>

          <input type="text" placeholder="請輸入收取禮品地址" v-model="form.address" />
        </div>
        <div class="field">
          <div class="field-label mb-3">Email</div>
          <input type="email" placeholder="請輸入Email" v-model="form.email" />
        </div>
      </div>

      <div class="button-update text-center mt-5">
        <img src="./images/momo_button_update.png" @click.prevent="handleSubmit" />
      </div>
    </div>
    <b-modal
      ref="confirm"
      title="請進行手機簡訊驗證"
      ok-title="驗證"
      ok-variant="outline-primary"
      :ok-disabled="!canSendVerifyCode"
      cancel-title="取消"
      cancel-variant="outline-secondary"
      modal-class="huggies-verify"
      @ok.prevent="handleNext">
      <div>
        <div class="sep"></div>
        <div class="wrap-content">
          <h6>
            請輸入發送至您手機的驗證碼
          </h6>
          <div class="mb-3" style="padding: 0">
            <input
              type="tel"
              class="form-control"
              id="code"
              name="code"
              placeholder="請輸入驗證碼"
              pattern="[0-9]{6}"
              v-model="verifyCode"
              :disabled="formLoading"
            />
          </div>
          <div id="countdown">
            <span id="sec">{{ seconds }}</span
            ><span> 秒後才可重新發送驗證碼</span>
            <h6
              id="resend"
              v-if="canResend"
              style="text-decoration: underline; cursor: pointer"
              @click="sendSmsVerificationCode"
            >
              重新發送驗證碼
            </h6>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import MomoNav from "./MomoNav";
import { mapGetters, mapActions, mapMutations } from 'vuex';
import Huggies from "@/apis/liff/Huggies";
import TwCitySelector from "tw-city-selector";

export default {
  data() {
    return {
      form: {
        name: '',
        mobile: '',
        email: '',
        county: '',
        district: '',
        zipcode: '',
        address: '',
      },
      formLoading: false,
      verifyCode: "",
    }
  },
  mounted() {
    this.form.name = this.me.name
    this.form.mobile = this.me.mobile
    this.form.county = this.me.county
    this.form.district = this.me.district
    this.form.zipcode = this.me.zipcode
    this.form.address = this.me.address
    this.form.email = this.me.email


    this.twCitySelector = new TwCitySelector({
      el: ".city-selector-set-has-value",
      elCounty: ".county",
      elDistrict: ".district",
      elZipcode: ".zipcode",
    })
  },
  components: {
    MomoNav,
  },
  computed: {
    ...mapGetters({
      me: 'liffHuggies/me',
      token: "liffHuggies/token",
      timer: "liffHuggies/timer",
      seconds: "liffHuggies/seconds",
      isLoading: "liffHuggies/isLoading",
    }),
    orgCode() {
      return this.$route.params.org_code;
    },
    canResend() {
      return !this.timer;
    },
    needVerify() {
      if (!this.me.phone_verified) {
        // 本來就沒驗證過手機，所以需要驗證
        return true
      }

      // 本來有驗證過手機，但是現在正換手機
      return this.form.mobile !== this.me.mobile && this.form.mobile.length === 10
    },
    canSendVerifyCode() {
      return !this.isLoading && this.needVerify && this.verifyCode.length === 6
    }
  },
  methods: {
    ...mapActions({
      updateCustomer: 'liffHuggies/updateCustomer',
      setLoading: "liffHuggies/setLoading",
      setTimer: "liffHuggies/setTimer",
      clearTimer: "liffHuggies/clearTimer",
    }),
    ...mapMutations({
      setMobile: "liffHuggies/SET_MOBILE",
    }),
    showVerifyDialog() {
      this.$refs.confirm.show();
      if (this.canResend) {
        this.clearTimer();
        this.sendSmsVerificationCode();
      }
    },
    async handleSubmit() {
      if (this.needVerify) {
        this.$swal({
          title: "請先驗證手機號碼",
          type: "warning",
        });
        return;
      }
      try {
        this.form.zipcode = document.querySelector("#zipcode").value

        let formNameMapping = {
          name: "姓名",
          mobile: "手機",
          county: "縣市",
          district: "區域",
          address: "地址",
          email: "Email",
          // zipcode: "郵遞區號", 此欄位為連動產生，不顯示給 user
        }
        let empty_keys = Object.entries(this.form)
          .filter(([key, value]) => !value) // eslint-disable-line no-unused-vars
          .flatMap(([key, value]) => [formNameMapping[key]]) // eslint-disable-line no-unused-vars
          .join(",")

        if (empty_keys) {
          this.$swal.fire({
            text: `${empty_keys} 為必填欄位`,
            type: "warning",
          })
          return
        }

        let data = await this.updateCustomer(this.form)
        if (data.need_phone_verify) {
          // 需要手機驗證
          this.$refs.confirm.show();
          this.setTimer();
          return
        }
        this.$swal.fire({
          title: "資料已更新",
          type: "success",
        });
      } catch (error) {
        this.$swal.fire({
          title: "更新失敗",
          type: "error",
        });
        console.error(error)
      }
    },
    async handleNext() {
      if (!this.verifyCode) {
        this.$swal.fire({
          text: "請輸入驗證碼",
          type: "warning",
        });
        return;
      }

      this.formLoading = true;
      this.setLoading(true);

      try {
        await Huggies.getInstance().verifyAndUpdateMobileNumber({
          'code': this.verifyCode,
          'mobile_phone': this.form.mobile,
        });
        this.clearTimer();
        this.$refs.confirm.hide()
        this.$swal.fire({
          title: "手機驗證完成",
          type: "success",
        });
        this.setMobile(this.form.mobile);
      } catch (error) {
        this.$swal.fire({
          text: error.message ?? '驗證失敗',
          type: "error",
        });
        console.error(error)
      } finally {
        this.setLoading(false);
        this.formLoading = false;
      }
    },

    async sendSmsVerificationCode() {
      this.verifyCode = ''
      this.setLoading(true);
      try {
        await Huggies.getInstance().sendSmsVerificationCode(this.form.mobile);
        this.setTimer();
      } catch (error) {
        this.$swal.fire({
          title: error.message ?? "驗證碼發送失敗",
          type: "error",
        });
        console.error(error)
      } finally {
        this.setLoading(false);
      }
    },
  }
};
</script>

<style lang="scss">
.form {
  margin-top: 20px;
}
.field {
  background: #fff;
  padding: 12px;
  border-radius: 15px;
  border: 2px solid #f5cf7a;
  margin-bottom: 10px;

  .field-label {
    color: #2a4221;
    font-weight: 500;
    font-size: 20px;
    line-height: 1;
  }

  small {
    color: #2a4221;
    font-size: 12px;
    /* line-height: 1; */
  }
  input {
    border: transparent;
    border-bottom: 1px solid #2a4221;
    width: 100%;
  }
}

.button-update {
  img {
    width: 35%;
  }
}
.huggies-verify + .modal-backdrop {
  opacity: 0.5 !important;
  background-color: #000 !important;
}


.huggies-verify .btn-outline-primary {
  &, &:hover, &:active, &:focus {
    background-color: transparent !important;
    border-color: #a14f9c !important;
    color: #a14f9c !important;
  }

  &:active, &:focus {
    box-shadow: none !important;
  }

  &:active {
    background-color: #a14f9c !important;
    color: #fff !important;
  }

  &:disabled {
    background-color: #fff !important;
    color: #ccc !important;
    border-color: #ccc !important;
    &:active, &:focus {
      box-shadow: none !important;
    }
  }
}
</style>
