<template>
  <Nav>
    <slot></slot>
  </Nav>
</template>

<script>
export default {};
</script>

<style lang="scss">
Nav {
  background: #35694b;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 84px;
  z-index: 100;

  .nav-item {
    width: 105px;
    + .nav-item {
      margin-left: 10px;
    }
    img {
      width: 100%;
    }
  }
}
</style>
